.main {
	background-color: var(--general---background-color);

	padding: 0.75rem 1rem 3rem;
}

.main__nav {
	--nav-link---text-decoration: none;
	--nav-link--hover-focus---text-decoration: none;
	--nav-link--active---text-decoration: none;
	--nav-link--location-active---color: var(--roadium-orange);
	font-size: 1.1rem;

	padding: 0 2rem 0.25rem;
	border-bottom: 2px solid var(--roadium-green);
	margin-bottom: 1rem;
}
