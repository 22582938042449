.header {
	width: fit-content;
}

.image {
	width: 70px;
	height: 70px;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.header__bar,
.header__drawer {
	color: var(--header---color);
	background-color: var(--header---background-color);

	--internal-link---color: var(--header---color);
	--external-link---color: var(--header---color);
	--external-link--visited---color: var(--header---color);
	--nav-link---color: var(--header---color);
	--nav-link---text-decoration: none;
	--nav-link--hover-focus---text-decoration: none;
	--nav-link--active---text-decoration: none;
}

.header__bar .header__external-nav-link,
.header__drawer .header__external-nav-link {
	--external-link---text-decoration: none;
	--external-link--hover-focus---text-decoration: none;
	--external-link--active---text-decoration: none;
}

.header__bar {
	width: var(--header__bar---width);
	height: 100vh;
	padding: 1rem 0;

	--box-shadow-color: #0000000f;
	box-shadow: var(--box-shadow-color) 0px 5px 20px 0px;

	position: relative;
}

.header__bar__site-title {
	font-size: 1.75rem;
	--internal-link---color: var(--primary-color);
	--internal-link---text-decoration: none;
	--internal-link--hover-focus---text-decoration: none;
	--internal-link--active---text-decoration: none;

	width: fit-content;
	height: fit-content;

	margin-bottom: 1.5rem;
}

.header__bar__icon-nav-column,
.header__bar__nav-column,
.header__bar__nav {
	width: 100%;
}

.header__bar__icon-nav-column {
	padding: 0 0.375rem;
}

.header__bar__title-and-search {
	width: fit-content;
	position: relative;
}

.header__bar__global-search,
.header__bar__nav {
	display: none;
}

@media (min-width: 768px) {
	.header__bar__drawer-button {
		display: none;
	}

	.header__bar__global-search,
	.header__bar__nav {
		display: block;
	}
}

.header__drawer {
	width: var(--header__drawer---width);
}

.header__drawer__body {
	--header__drawer__body---h-padding: 1.5rem;
	padding: 2.5rem var(--header__drawer__body---h-padding);
}

.header__drawer__global-search {
	--global-search__simple__search-bar---width: calc(
		var(--header__drawer---width) - var(--header__drawer__body---h-padding) * 2
	);
}

.header__drawer__nav a {
	width: fit-content;
}

.header__bar__nav a,
.header__drawer__nav a {
	padding: 0.5rem;
	border-radius: 0.5rem;
}

.header__bar__nav a:hover:not(.nav-link--location-active),
.header__drawer__nav a:hover:not(.nav-link--location-active) {
	--nav-link--hover-focus---color: var(--nav-link---color);
	--external-link--hover-focus---color: var(--external-link---color);
	background-color: rgba(var(--primary-color-rgb), 0.25);
}

.nav-link--location-active {
	--nav-link--location-active---color: #fff;
	--external-link--location-active---color: #fff;
	background-color: var(--primary-color);
}

.header__bar__nav a {
	font-size: 0.7rem;
	width: 100%;
	--icon---size: 1.25rem;
}

.header__bar__nav a svg {
	stroke-width: 1.5;
}

@media (max-width: 767px) {
	html {
		--header__bar---width: 0;
		--header__bar---height: 50px;
	}

	.header {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		height: var(--header__bar---height);
		width: 100%;
	}

	.header__bar {
		width: 100%;
		height: var(--header__bar---height);
		flex-direction: row;
	}

	.header__bar__nav-column {
		display: none;
	}

	.header__bar__icon-nav-column {
		flex-direction: row;
		justify-content: space-between;
		width: calc(50% + 2rem);
	}

	.header__bar__site-title {
		display: block;
		width: 2rem;
		height: 2rem;
		margin: 0;
	}

	.header__bar__site-title img {
		width: 2rem;
		height: 2rem;
	}

	.header__drawer__nav a {
		width: 100%;
		padding: 1rem;
	}

	.header__drawer__nav svg {
		margin-right: 1rem;
	}
}
