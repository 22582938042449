.user-button {
	--size: 2.25rem;
	width: var(--size);
	height: var(--size);
	--icon---size: 1.3rem;
}

.user-button__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.user-tooltip {
	--tooltip__body---color: var(--general---color);
	--tooltip__footer---color: var(--general---color);
	--tooltip__footer---background-color: rgba(var(--primary-color-rgb), 0.1);
	--tooltip---background-color: #fff;
	--tooltip---font-size: 1rem;
}

.user-tooltip__link {
	--internal-link---text-decoration: none;
	--internal-link--hover-focus---text-decoration: none;
	--internal-link--active---text-decoration: none;

	--icon---size: 1.25rem;
}

.user-tooltip__link svg {
	stroke-width: 1.5;
}

.user-tooltip__app-info {
	color: var(--primary-color);
	font-size: 0.9rem;
}

.user-tooltip__app-info__version,
.user-tooltip__app-info__instance-id {
	font-family: monospace;
}

.user-tooltip__app-info__version {
	margin-right: 1rem;
}

@media (max-width: 767px) {
	.user-button {
		margin-right: 0.375rem;
	}
}
