html {
	/* define theme CSS custom properties here */
	--header__bar---height: 0rem;
	--header__bar---width: 6rem;
	--header__drawer---width: 90vw;
	--header__side-bar---width: 13rem;
	/* --header---height is used by other elements that depend on the header, the header's height is set internally. */
	--header---height: 0px;

	--brand-color--primary: #72a340;

	--primary-color: #0284fe;
	--primary-color-rgb: 2, 132, 254;

	--roadium-orange: #f1965a;
	--roadium-green: var(--brand-color--primary);
	--roadium-purple: #bd72df;
	--roadium-yellow: #f5bd6a;
	--roadium-red: #eb392c;

	--general---theme-color: var(--roadium-green);
	--general---theme-text-color: #0a0a0a;

	--header---color: var(--general---color);
	--header---background-color: var(--general---background-color);
	--footer---color: var(--general---theme-text-color);
	--footer---background-color: var(--general---theme-color);
	--general__header---color: var(--general---theme-text-color);
	--general__header---background-color: var(--general---theme-color);

	--general---box-shadow-color: #0002;
	--general---box-shadow: 2px 2px 4px var(--general---box-shadow-color), 0px 0px 4px var(--general---box-shadow-color);
	--general---box-shadow--bottom-right: 4px 2px 4px -2px var(--general---box-shadow-color),
		2px 4px 4px -2px var(--general---box-shadow-color);

	--heading--level-1---margin: 0 0 1rem;

	--button---border-radius: 10rem;
	--general__tile---border-radius: 1rem;
	--modal---border-radius: var(--general__tile---border-radius);

	--nav-link--active---color: var(--roadium-red);
	--internal-link--active---color: var(--roadium-red);

	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
		"Helvetica Neue", sans-serif;
}

body {
	--table__header---top: var(--header---height);

	color: var(--general---color);
	background-color: var(--general---theme-color);

	--scroll-target---scroll-margin-top: calc(var(--header---height) + var(--floating-elements--top---height, 0px));
	--scroll-target---scroll-margin-bottom: var(--floating-elements--bottom---height, 0px);
	--table__scroll-target---scroll-margin-top: var(--scroll-target---scroll-margin-top);
	--table__scroll-target---scroll-margin-bottom: var(--scroll-target---scroll-margin-bottom);
}

body.body--with-header {
	--pop-up---top: calc(var(--header---height) + 1rem);
	--pop-up---left: calc(var(--header---width) + 1rem);

	margin-left: var(--header---width);
	overflow-x: hidden;
}

@media (min-width: 768px) {
	body.body--with-header {
		--header---width: var(--header__bar---width);
	}
}

body.body--with-header-side-bar {
	--pop-up---right: calc(var(--header__side-bar---width) + 1rem);
	--header__bar__navigation-toolbar---width: calc(100vw - var(--header__side-bar---width));

	margin-right: var(--header__side-bar---width);
	overflow-x: hidden;
}

@media print {
	body,
	body.body--with-header,
	body.body--with-header.body--with-navigation-toolbar {
		--header---height: 0px;
		--header---width: 0px;
		--header__bar---width: 0px;
	}
}

main {
	width: 100%;
	min-height: 100vh;
}

body.body--with-header main {
	min-height: calc(100vh - var(--header---height));
}

.scroll-target {
	scroll-margin-top: var(--scroll-target---scroll-margin-top);
	scroll-margin-bottom: var(--scroll-target---scroll-margin-bottom);
}

.fit-content {
	width: fit-content;
}

.block {
	display: block;
}

.print-only {
	display: none;
}

@media print {
	.no-print {
		display: none;
	}

	.print-only {
		display: initial;
	}
}

.tile-background {
	background-color: #f9f9fa;
}

@media print {
	.tile-background {
		background-color: #fff;
	}
}

.tile {
	background-color: var(--general---background-color);
	box-shadow: var(--general---box-shadow);

	--tile---border-radius: var(--general__tile---border-radius);
	border-radius: var(--tile---border-radius);
}

.tile > :first-child {
	border-top-right-radius: var(--tile---border-radius);
	border-top-left-radius: var(--tile---border-radius);
}

.tile > :last-child {
	border-bottom-right-radius: var(--tile---border-radius);
	border-bottom-left-radius: var(--tile---border-radius);
}

.tile__header {
	padding: 1rem 1.5rem 0;
}

.tile__footer {
	padding: 0.5rem 1.5rem 1rem;
}

@media print {
	.tile {
		background-color: transparent;
		box-shadow: none;
	}
}
